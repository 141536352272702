$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($('body').hasClass('open')){
            $('body').removeClass('open');
            $('header nav .menuwrapper').slideUp();
        }else{
            $('body').addClass('open');
            $('header nav .menuwrapper').slideDown();
        }
    });

    heightHomeImage();

});

//HEIGHT HEADERIMAGE
function heightHomeImage(){
    $screenHeight = $(window).height();
    $headerHeight = $('header').outerHeight();
    $height = $screenHeight - $headerHeight - 60;

    $("#headerImage").css({'height': $height});
}

$(window).resize(function(){
    heightHomeImage();
});




equalheight = function(container){
var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {
   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;
   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}

$(window).load(function() {
    equalheight('#attachmentstechnische ul li');
});
$(window).resize(function(){
    equalheight('#attachmentstechnische ul li');
});
